<template >
    <v-container class="view-login" fluid fill-height>
        <v-layout align-center justify-center row fill-height >            
            <v-card class="login">
                <form>
                    <img src='../assets/img/logo.png'>
                    <div class="color--secondary">
                        <label v-if="!recover_passwd" >{{ $t('login_label_welcome')}}</label>
                        <label v-if="recover_passwd" >{{ $t("login_label_recover")}}</label>
                    </div>
                    <v-text-field v-if="!recover_passwd"  v-model="credentials.usuario" v-bind:label="$t('login_usuario')" required></v-text-field>
                    <v-text-field v-if="!recover_passwd" @keypress.enter="login" type="password" v-model="credentials.password" v-bind:label="$t('login_contrasenya')" required></v-text-field>
                    <v-btn v-if="!recover_passwd" class="button" color="#808080" @click.stop="login" >{{$t('login_btn')}}</v-btn>
                    <!-- <v-btn v-if="!recover_passwd" class="button color-third mt-4" @click="recover_passwd = !recover_passwd" >{{$t('login_btn_recoverpass')}}</v-btn> -->
                    <!--Modo recuperar contrasenya -->
                    <v-text-field v-if="recover_passwd" v-model="credentials.usuario" v-bind:label="$t('revocer_direccion')" required></v-text-field>
                    <v-btn v-if="recover_passwd" class="button" color="#808080" @click.stop="revocerPassword" >{{$t('recoer_btn')}}</v-btn>
                    <v-btn v-if="recover_passwd" class="button color-third mt-4" @click.stop="recover_passwd = !recover_passwd" >{{$t('return_login_btn')}}</v-btn>
                </form>
            </v-card>
        </v-layout>
        <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'"
            :timeout="snackbar.timeout" :vertical="snackbar.mode === 'vertical'">
            {{ snackbar.text }}
        </v-snackbar>
    
    </v-container>
</template>

<script>
//import { ResourceFactory } from '@/services/resources/resourceFactory'
//import Tools from '@/services/tools.service'
//import { from } from 'rxjs'

//const AuthResource = ResourceFactory.get('auth')
// const _user = new UsersDbService()
import { gw } from "@/gw.js";

export default {
    mixins: [gw],
    name: 'Login',
    data: () => ({
        credentials : {
            empresa: '',
            usuario: '',
            password: ''
        },
        user: {},
        snackbar: {
            snackbar: false,
            color: 'grey',
            mode: '',
            timeout: 6000,
            text: ''
        },
        recover_passwd: false
    }), 
    mounted: function() {
        //Autologin. Si existen las 3 cookies se intenta realizar el login. 
        if(this.getCookie('LK') && this.getCookie('LP') && this.getCookie('LS')){
            //this.login(true)
        }
    },
    methods: {
        revocerPassword(){
            let post = this.usuario;
            let _this = this;
              this.api('/peticion/',post).then(
                (data) => {

                },
                 (err) => {
                    console.log(err)
                    _this.snackbar.color = 'error';
                    _this.snackbar.text = this.$i18n.t('login_msg_error');
                    _this.snackbar.snackbar = true;
                }
            );//api
        },
        login(autologin=false){

           this.closekeyboard();

            // if(autologin){
            //     let post = {
            //         LK: this.getCookie('LK'),
            //         LP: this.getCookie('LP'),
            //         LS: this.getCookie('LS')
            //     }; 
            // }
            // else{
            //     let post = this.credentials;
            // }
            let post = this.credentials;
            //let _this = this;
            this.api('/auth',post).then(
                (data) => {
                    let resp = data;
                    if(resp.success){
                        // guardamos en sqlite y en la variable global currentUser
                       //let token = resp.datos['token'];
                        //let cliente = resp.datos['clie'];
                        
                        let auth = {
                            'access_token': resp.datos['access_token'],
                            //'auth': resp.datos['auth'],
                            'nombreuser' : resp.datos['nombre'],
                            'coduser' : resp.datos['coduser'],
                            'rol' : resp.datos['rol']
                            //'cliente': resp.datos['auth']['cliente']
                        }
                        
                        
                        //Estableciendo cookies
                        //document.cookie = "AT="+resp.datos['AT']+"; max-age=3600; path=/";
                        document.cookie = "LK="+resp.datos['LK']+"; max-age=604800; path=/";
                        document.cookie = "LP="+resp.datos['LP']+"; max-age=604800; path=/";
                        document.cookie = "LS="+resp.datos['LS']+"; max-age=604800; path=/";




                        /* if(auth.rol=="cliente"){
                            auth.cliente=auth.coduser;
                            auth.nombrecliente=auth.nombreuser;
                            //auth.codcliente="000000";
                            //auth.nombrecliente="PRUEBA";
                            let post = {"codcliente": auth.coduser};
                            this.api("peticion/cliente",post).then(
                                (data) => { 
                                    auth.datoscliente = data.datos;
                                    this.setAuth(auth);
                                }
                            ); 
                            this.apiPreciosCat(auth.cliente);
                        }
                        else{
                            auth.cliente=null;
                            auth.nombrecliente=null;
                        }*/
                        //console.log(auth);
                        this.setAuth(auth);

                        switch (auth.rol) {
                        case 'operario':
                            this.to("/ordenestrabajo");
                            break;
                        }
                    }else {
                        this.snackbar.color = 'error';
                        this.snackbar.text = this.$i18n.t('login_msg_error');
                        this.snackbar.snackbar = true;
                    }
                },
                (err) => {
                    console.log(err)
                    this.snackbar.color = 'error';
                    this.snackbar.text = this.$i18n.t('login_msg_error');
                    this.snackbar.snackbar = true;

                }
            );//api
        }//loading
    }//methods
}
</script>
